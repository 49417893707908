import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => { ref.current = value })
  return ref.current
}

export default function useLocationPathChange(action) {
  const location = useLocation()
  const prevLocation = usePrevious(location) 

  useEffect(() => {
    // DO NOT REMOVE THIS. It skips action on first render to prevent a second render due to wellby's architecture.
    if (typeof prevLocation === 'undefined') {
      return
    }

    // Handle scroll behavior immediately
    const handleScroll = () => {
      if (location.hash) {
        const element = document.getElementById(location.hash.slice(1))
        if (element) {
          window.dispatchEvent(new Event('hashchange'))
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'instant' })
      }
    }

    // Execute scroll first
    handleScroll()

    // Then execute any additional page change actions
    action(location.pathname)

  }, [location]) // Re-run effect when location changes
}
