import React, { useState } from 'react'

import { Markdown, RouterLinkTemplate } from 'Components'
import PlusSVG from 'SVGs/plus.svg'
import { addWebPToImageURL } from 'Helpers/utilities'

const FaQRenderer = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  if (!data || data.length === 0) return null

  function handleClick(e, index) {
    e.preventDefault()
    if (index === activeIndex) {
      setActiveIndex(null)
      return
    }
    setActiveIndex(index)
  }

  return (
    <>
      {data.map(({ Question, Answer }, i) => {
        const markdown = { __html: Answer }
        return (
          <div key={i} className="border-b-2 border-b-violet mb-6">
            <div className="pb-6">
              <button type="button" className='flex w-full justify-between font-display font-semibold lg:text-xl text-left text-violet hover:text-violet-dark hover:cursor-pointer'
                onClick={(event) => handleClick(event, i)}
                tabIndex="0">
                <div>{Question}</div>
                <div className="ml-8">
                  {activeIndex === i ? <PlusSVG className="w-6 rotate-45" /> : <PlusSVG className="w-6" />}
                </div>
              </button>
              <div className={`mt-4 transition-height duration-100 font-display font-medium text-sm lg:text-base ${activeIndex === i ? 'animate-fade-in-down opacity-1 h-auto' : 'opacity-0 h-0 hidden'} lg:prose prose-sm`} dangerouslySetInnerHTML={markdown} />
            </div>
          </div>
        )
      })}
    </>
  )
}



const TransitionInfoAndCommonQuestionsBlock = ({ data, inEditMode }) => {
  const { Image, Heading, Content, ContentUrl, Subheading, FrequentlyAskedQuestions } = data
  const Link = ContentUrl.Value[0]

  const LeftContainer = () => {
    return (
      <div className="grid lg:block">
        <div className="mb-8">
          <Markdown data={Content} name='Content' inEditMode={inEditMode} className='font-display text-xl font-medium' />
        </div>
        {Link && (
          <RouterLinkTemplate Path={Link.Href} inEditMode={inEditMode}>
            <span data-epi-edit="ContentUrl" className="text-xl font-semibold font-display text-violet hover:text-violet-light">{Link.Text}</span>
          </RouterLinkTemplate>
        )}
        {!Link && inEditMode && (
          <span data-epi-edit="ContentUrl" className="text-xl font-medium font-display text-violet">
            Click to edit Link
          </span>
        )}
      </div>
    )
  }

  const RightContainer = () => {
    return (
      <div className="-mt-2">
        <h4 className="lg:text-2xl text-xl font-medium font-display tracking-tight px-4 pb-5 text-center md:text-left">
          <span data-epi-edit="Subheading">
            {Subheading}
            {inEditMode && !Subheading && 'Click to edit Subheading'}
          </span>
        </h4>
        <span data-epi-edit="FrequentlyAskedQuestions">
          <FaQRenderer data={FrequentlyAskedQuestions} />
          {inEditMode && !FrequentlyAskedQuestions && 'Click to edit FAQs'}
        </span>
      </div>
    )
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <div className="xl:mx-32 lg:mx-28 md:mx-12 sm:mx-8 mx-6
                      lg:py-10 md:py-7 py-4">
        <div className="mb-12">
          <div data-epi-edit="Image" className="grid justify-items-center">
            {Image && <img data-src={addWebPToImageURL(Image?.Url)} alt={Image?.AltTag} width="150" height="135" className='lazy' />}
            {inEditMode && !Image && 'Click to edit Image'}
          </div>
        </div>
        <h2 className="lg:text-4xl text-xl font-medium font-display mb-8 lg:block grid">
          <span data-epi-edit="Heading">
            {Heading}
            {inEditMode && !Heading && 'Click to edit Heading'}
          </span>
        </h2>
        <div className="grid lg:grid-cols-2 lg:gap-4 gap-8 lg:justify-items-stretch">
          <LeftContainer />
          <RightContainer />
        </div>
      </div>
    </div>
  )
}

export default React.memo(TransitionInfoAndCommonQuestionsBlock)
