import React from 'react'

import { RouterLinkTemplate } from 'Components'

const LeftPageLinksRenderer = ({ data, name, inEditMode }) => {
  if (!data || !data.Value || data.Value.length === 0) {
    if (!inEditMode) return null

    return (
      <ul className="leading-10 flex items-center" data-epi-edit={name}>
        <li className="text-sm text-gray-500">Click to edit References</li>
      </ul>
    )
  }

  return (
    <ul className="leading-10 flex items-center" data-epi-edit={name}>
      {data?.Value?.map((link, i) => (
        <li key={i}
          className={`inline-block 2xl:text-base lg:text-lg text-base hover:text-violet-light ${i === data.length - 1 ? '' : '2xl:pr-7 xl:pr-12 lg:pr-8 md:pr-5 pr-3'}`}>
          <RouterLinkTemplate Path={link.Href} Target={link.Target} inEditMode={inEditMode}>
            {link.Text}
          </RouterLinkTemplate>
        </li>
      ))}
    </ul>
  )
}

export default LeftPageLinksRenderer
