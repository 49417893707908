import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'

import CardTile from './CardTile'
import CarouselRenderer from './CarouselRenderer'
import Layout from './Layout'
import Marker from './Marker'
import SearchBar from './SearchBar'
import { getLatLng } from '.'

const LocationServiceBlock = ({ data, inEditMode }) => {
  const {
    ApiKey,
    DefaultZoom,
    Center,
    NotaryServiceText,
    NotaryServiceIcon,
    CoinMachinesText,
    CoinMachinesIcon,
    ItmText,
    ItmIcon,
    AtmText,
    AtmIcon,
    DriveThruText,
    DriveThruIcon,
    CoffeeShopsText,
    CoffeeShopsIcon,
    DepositBoxText,
    DepositBoxIcon,
    SearchHeading,
    SearchPlaceholder,
    OperatingHoursText,
    MainContentArea,
    SollutionCenterTab,
    SharedBranchesTab,
    AllPointATMSTab
  } = data

  const defaultCenter = Center
    ? getLatLng(Center)
    : { lat: 29.5587619, lng: -95.1119534 }

  const baseFilter = {
    NotaryService: false,
    CoinMachines: false,
    Itm: false,
    Atm: false,
    DriveThru: false,
    CoffeeShops: false,
    DepositBox: false
  }
  const [selectedItem, setSelectedItem] = useState(null)
  const [center, setCenter] = useState(defaultCenter)
  const [filterRule, setFilterRule] = useState(baseFilter)
  const [filteredData, setFilteredData] = useState(MainContentArea)
  const [clickFilter, setClickFilter] = useState(false)

  const shouldFilter = (rule) => {
    for (const key in rule) {
      if (rule[key]) return true
    }
    return false
  }

  function handleFilter (e, prop) {
    setClickFilter(true)
    const cloneRule = cloneDeep(filterRule)
    if (e && filterRule[prop] !== true) {
      cloneRule[prop] = true
    } else {
      cloneRule[prop] = false
    }

    setFilterRule(cloneRule)
  }

  useEffect(() => {
    if (!clickFilter) {
      setFilteredData(MainContentArea)
      return
    }

    if (shouldFilter(filterRule)) {
      const filterOptions = {}
      for (const key in filterRule) {
        if (filterRule[key]) {
          filterOptions[key] = true
        }
      }

      setFilteredData(filter(MainContentArea, filterOptions))
    } else {
      setFilteredData(MainContentArea)
    }
  }, [clickFilter, filterRule])

  const TabSelection = ({ data, activeIndex, setActiveIndex }) => {
    if (data?.length === 0) return null
    return (
      <div className="xl:ml-24 lg:ml-20 md:ml-12 flex items-end border-b-2 border-gray-300  scroll-smooth hover:scroll-auto overflow-visible  hidden-scrollbars overflow-x-scroll whitespace-nowrap lg:text-lg font-display text-sm">
        {data?.map((item, i) => (
          <button type="button" key={i} onClick={() => setActiveIndex(i)} 
              className={`${i === activeIndex
                ? 'border-fuchsia nav-shadow-fuchsia'
                : 'border-transparent'
              } border-b-2 pb-2 mr-8 ml-2 md:ml-0 hover:text-gray-600 font-medium lg:font-normal cursor-pointer`}>
            {item}
          </button>))}
      </div>
    )
  }

  const SharedBranchess = () => {
    return (
      <div className="container mx-auto max-w-screen-xl">
        <div className="md:my-8 my-6 xl:mx-28 lg:mx-24 md:mx-12 mx-10 scroll-smooth hover:scroll-auto overflow-visible  hidden-scrollbars overflow-x-scroll whitespace-nowrap">
          <div className="">
            <form action="https://co-opcreditunions.org/locator/search-results/" method="get">
              <input type="hidden" name="loctype" value="AS" />
              <div className="flex gap-x-6">
              <div className="mt-6">
                <img data-src="https://co-opcreditunions.org/wp-content/themes/coop019901/img/logo@2x.png" name="cooplogo" style={{ width: '70.2px', height: '56.4px' }} className='lazy' />
              </div>
              <div className="">
                <div className="md:text-lg" >ATM Locator</div>
                <div className="md:text-2xl text-xl font-semibold my-2">Search by Zip:</div>
                <div className=" flex gap-x-3" style={{ width: '112px', height: '45px' }}>
                  <input name="zip" type="text" className="textBox  focus:outline-none rounded-full font-medium font-body border border-indigo-600 rounded-full leading-5 md:px-3 px-2" size="13" />
                  <input type="submit" value="Find Locations" className="focus:outline-none rounded-full font-medium font-body text-white md:text-base sm:text-sm text-xs bg-violet hover:bg-violet-dark border-2 border-violet hover:border-violet-dark lg:py-2.5 py-2 md:px-8 sm:px-6 px-4 whitespace-nowrap" />
                </div>
              </div>
              </div>
              <br style={{ clear: 'left' }} />
            </form>
          </div>
          </div>
      </div>
    )
  }

  const AllpointATMs = () => {
    return (
      <div className="container mx-auto max-w-screen-xl">
        <div className="md:my-8 my-6 xl:mx-28 lg:mx-24 md:mx-12 mx-10 scroll-smooth hover:scroll-auto overflow-visible  hidden-scrollbars overflow-x-scroll whitespace-nowrap">
        <iframe src="https://allpoint.locatorsearch.net/" width="900" height="725" name="atmlocator">
          Your browser doesnt support iframes
        </iframe>
        </div>
      </div>
    )
  }

  const [activeIndex, setActiveIndex] = useState(0)
  let tab
  if (activeIndex === 1) {
    tab = <SharedBranchess />
  }
  if (activeIndex === 2) {
    tab = <AllpointATMs />
  }

  return (
    <div className="container mx-auto max-w-screen-xl">
      <TabSelection className="mt-2" data={[SollutionCenterTab, SharedBranchesTab, AllPointATMSTab]} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
      {tab}
      {activeIndex === 0 &&
        <div className="container mx-auto max-w-screen-xl">
          <div className="md:my-8 my-6">
            <Layout>
              <div className="lg:mx-0 md:mx-8">
                <h4 data-epi-edit="SearchHeading" className="font-display font-medium lg:text-2xl md:text-lg text-base self-center md:mb-7 mb-2">
                  {SearchHeading}{' '} {inEditMode && !SearchHeading && 'Click to edit Search Heading'} </h4>
                <div className="md:grid grid-cols-2">
                  <SearchBar data={SearchPlaceholder} name="SearchPlaceholder" inEditMode={inEditMode} apiKey={ApiKey} onResultFound={(location) => setCenter(location)}/>
                  {(selectedItem?.OpeningHours?.Value?.length > 0 || inEditMode) && (
                    <div className="font-body my-2">
                      <p data-epi-edit="OperatingHoursText" className="font-medium lg:text-xl md:text-base text-sm self-center md:mb-2"> {OperatingHoursText || 'Operating Hours:'}</p>
                      {selectedItem?.OpeningHours?.Value?.length > 0 && selectedItem.OpeningHours.Value.map((text) => (<p key={text} className="md:text-base text-sm">{text}</p>))}
                    </div>
                  )}
                </div>
              </div>
              <div className="lg:mx-0 md:mx-8">
                <div className="w-full lg:my-8 sm:my-6 my-4" style={{ height: '55vh' }}>
                  <GoogleMapReact bootstrapURLKeys={{ key: ApiKey }} defaultCenter={defaultCenter} center={center} defaultZoom={DefaultZoom || 10} onChildClick={(idx) => {
                    setSelectedItem(MainContentArea[Number(idx)])
                    setClickFilter(false)
                    setFilterRule({
                      NotaryService: MainContentArea[Number(idx)].NotaryService,
                      CoinMachines: MainContentArea[Number(idx)].CoinMachines,
                      Itm: MainContentArea[Number(idx)].Itm,
                      Atm: MainContentArea[Number(idx)].Atm,
                      DriveThru: MainContentArea[Number(idx)].DriveThru,
                      CoffeeShops: MainContentArea[Number(idx)].CoffeeShops,
                      DepositBox: MainContentArea[Number(idx)].DepositBox
                    })
                    const { Coordinates } = MainContentArea[Number(idx)]
                    setCenter(getLatLng(Coordinates))
                  }}
                    onChange={({ center }) => { setCenter(center) }}>
                    {filteredData && filteredData.length > 0 && filteredData.map((location, i) => {
                      const { Coordinates } = location
                      const { lat, lng } = getLatLng(Coordinates)
                      return (
                          <Marker key={i} lat={lat} lng={lng} isActive={location === selectedItem} />
                      )
                    })}
                  </GoogleMapReact>
                </div>
              </div>
            </Layout>
            <div className="lg:mx-12 md:mx-8">
              <div className="md:mx-0 mx-10 scroll-smooth hover:scroll-auto overflow-visible  hidden-scrollbars overflow-x-scroll whitespace-nowrap">
                <div className="m-1 flex gap-x-4">
                  {(NotaryServiceText || inEditMode) && (<CardTile isActive={selectedItem?.NotaryService} text={NotaryServiceText} textName="NotaryServiceText" icon={NotaryServiceIcon} iconName="NotaryServiceIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'NotaryService')} />)}
                  {(CoinMachinesText || inEditMode) && (<CardTile isActive={selectedItem?.CoinMachines} text={CoinMachinesText} textName="CoinMachinesText" icon={CoinMachinesIcon} iconName="CoinMachinesIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'CoinMachines')} />)}
                  {(ItmText || inEditMode) && (<CardTile isActive={selectedItem?.Itm} text={ItmText} textName="ItmText" icon={ItmIcon} iconName="ItmIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'Itm')} />)}
                  {(AtmText || inEditMode) && (<CardTile isActive={selectedItem?.Atm} text={AtmText} textName="AtmText" icon={AtmIcon} iconName="AtmIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'Atm')} />)}
                  {(DriveThruText || inEditMode) && (<CardTile isActive={selectedItem?.DriveThru} text={DriveThruText} textName="DriveThruText" icon={DriveThruIcon} iconName="DriveThruIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'DriveThru')} />)}
                  {(CoffeeShopsText || inEditMode) && (<CardTile isActive={selectedItem?.CoffeeShops} text={CoffeeShopsText} textName="CoffeeShopsText" icon={CoffeeShopsIcon} iconName="CoffeeShopsIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'CoffeeShops')}/>)}
                  {(DepositBoxText || inEditMode) && (<CardTile isActive={selectedItem?.DepositBox} text={DepositBoxText} textName="DepositBoxText" icon={DepositBoxIcon} iconName="DepositBoxIcon" inEditMode={inEditMode} setFilter={(e) => handleFilter(e, 'DepositBox')} />)}
                </div>
              </div>
              <div className="xl:mx-20 lg:mx-16 mx-10 grid gap-y-4">
                <CarouselRenderer data={filteredData} name="MainContentArea" inEditMode={inEditMode} center={center} />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default React.memo(LocationServiceBlock)
