import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'

import { useLocationPathChange } from 'Hooks'
import { getNavbarLinksWithActiveState, CtaContentBlock } from '.'

const Navbar = ({ data, name, inEditMode }) => {
  if (data?.Links?.length === 0) {
    if (!inEditMode) return null
    return <nav data-epi-edit={name} className="bg-violet lg:py-4 justify-center text-white font-display lg:text-lg hidden lg:flex">Click to edit</nav>
  }

  const [menuVisibility, setMenuVisibility] = useState(null)
  const [navData, setNavData] = useState(data)
  const menuDiv = useRef(null)

  useEffect(() => {
    setNavData(getNavbarLinksWithActiveState(window.location.pathname, data))
  }, [])

  useLocationPathChange((pathname) => {
    setNavData(getNavbarLinksWithActiveState(pathname, navData))
  })

  const onClickNavLinkHandler = () => {
    if (menuDiv.current) menuDiv.current.className += ' opacity-0'
    setTimeout(() => setMenuVisibility(null), 500)
  }

  return (
    <nav id='desktopNavigation'
      data-epi-edit={name}
      onMouseLeave={() => setMenuVisibility(null)}
      className="lg:text-lg hidden lg:block z-50">
      <ul className="bg-violet justify-center font-medium font-display text-white flex overflow-hidden">
        {navData?.map((item, i) => {
          const { Name, Active } = item
          return (
            <li key={i} className={`xl:mx-8 lg:mx-4 px-3 cursor-pointer hover:bg-violet-dark
              ${Active ? 'border-b-4 border-gold' : ''} ${i === menuVisibility ? 'bg-violet-dark' : ''}`}
              onMouseOver={() => setMenuVisibility(i)}>
              <div className="hover:text-gray-200 lg:py-4 text-center">{Name}</div>
            </li>
          )
        })}
      </ul>
      <div className={`${menuVisibility != null ? '' : 'hidden'} h-auto w-full absolute z-50 transition-opacity duration-500`} ref={menuDiv}>
        <div className="bg-white shadow py-4 flex justify-center">
          {navData?.map((item, i) => {
            const { Links, CtaContent } = item
            return (
              <div key={i} className={`${CtaContent ? 'grid grid-cols-2 max-w-screen-xl px-8' : ''} ${i !== menuVisibility ? 'hidden' : ''}`}>
                <div className={Links?.length > 1 ? 'grid grid-cols-2' : 'justify-self-center'}>
                  {Links?.map((link, linkIndex) => {
                    const { ParentText, ParentUrl, Childrens } = link
                    return (
                      <ul key={linkIndex} className="xl:mx-10 lg:mx-6 py-2">
                        <li className="font-medium font-display">
                          {ParentUrl && <NavLink to={ParentUrl} className={({ isActive }) => isActive ? 'text-violet-bright' : 'hover:text-violet-bright'} end onClick={onClickNavLinkHandler}>{ParentText}</NavLink>}
                          {!ParentUrl && <span>{ParentText}</span>}
                        </li>
                        {Childrens?.map((child, childIndex) => {
                          const { Text, Href, Target } = child
                          const path = Href.toLowerCase()
                          if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('tel:') || path.endsWith('.pdf')) {
                            return <li key={childIndex} className="font-body leading-tight"><a href={Href} className="hover:text-violet-bright" target={Target}>{Text}</a></li>
                          }
                          return <li key={childIndex} className="font-body leading-tight"><NavLink to={Href} className={({ isActive }) => isActive ? 'text-violet-bright' : 'hover:text-violet-bright'} end onClick={onClickNavLinkHandler}>{Text}</NavLink></li>
                        })}
                      </ul>
                    )
                  })}
                </div>
                {CtaContent && <CtaContentBlock data={CtaContent} inEditMode={inEditMode} handleClick={() => setMenuVisibility(null)} />}
              </div>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default React.memo(Navbar)
